import React from "react";
import { Link } from "react-router-dom";
import balao_01 from "../../images/balao_01.png";
import balao_02 from "../../images/balao_02.png";
import balao_03 from "../../images/balao_03.png";
import "../../styles.css";
import Cabecalho from "../../componentes/cabecalho";
import Rodape from "../../componentes/rodape";

export const PJ = ({}) => {
  return (
    <div>
      <div className="estrutura_cabecalho">
        <Cabecalho />
      </div>
      <div className="estrutura_conteudo">
        <div className="container">
          <div className="card">
            <a
              href="https://www.unimedsudoeste.com.br/novo/empresa_login.php?a=4"
              target={"_blank"}
            >
              <img src={balao_01} alt="FATURA PÓS." className="image_card" />
            </a>
          </div>
          <div className="card">
            <a
              href="https://www.unimedsudoeste.com.br/novo/empresa_login.php?a=4"
              target={"_blank"}
            >
              <img
                src={balao_02}
                alt="FATURA PRÉ COM MENSALIDADES ATÉ JANEIRO DE 2022."
                className="image_card"
              />
            </a>
          </div>
          <div className="card">
            <Link to={"/PORTAL_NOVO"}>
              <img
                src={balao_03}
                alt="FATURA PRÉ COM MENSALIDADES A PARTIR DE FEVEREIRO DE 2022."
                className="image_card"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="estrutura_rodape">
        <Rodape />
      </div>
    </div>
  );
};

export default PJ;
