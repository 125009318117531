import React from "react";
import { Link } from "react-router-dom";
import vencimento1 from "../../images/balao_vencimento_01.png";
import vencimento2 from "../../images/balao_vencimento_02.png";
import "../../styles.css";
import Cabecalho from "../../componentes/cabecalho";
import Rodape from "../../componentes/rodape";

export const Demitido_Aposentado = ({}) => {
  return (
    <div>
      <div className="estrutura_cabecalho">
        <Cabecalho />
      </div>
      <div className="estrutura_conteudo">
        <div className="container">
          <div className="card">
            <a
              href="http://191.35.130.138:8099/segundavia/autentica.aspx"
              target={"_blank"}
            >
              <img
                src={vencimento1}
                alt="SEU PLANO FOI CONTRATADO ATÉ DEZEMBRO DE 2021."
                className="image_card"
              />
            </a>
          </div>
          <div className="card">
            <Link to={"/PASSO_A_PASSO"}>
              <img
                src={vencimento2}
                alt="SEU PLANO FOI CONTRATADO ATÉ DEZEMBRO DE 2021."
                className="image_card"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="estrutura_rodape">
        <Rodape />
      </div>
    </div>
  );
};

export default Demitido_Aposentado;
