import React from "react";
import { Link } from "react-router-dom";
import faturas_marco from "../../images/banner-maio.png";
import demais_faturas from "../../images/demais_faturas.png";
import "../../styles.css";
import Cabecalho from "../../componentes/cabecalho";
import Rodape from "../../componentes/rodape";

export const Faturas = ({}) => {
  return (
    <div>
      <div className="estrutura_cabecalho">
        <Cabecalho />
      </div>
      <div className="estrutura_conteudo">
        <div className="container">
          <div className="card">
            <Link to={"/PORTAL_NOVO"}>
              <img src={faturas_marco} className="image_card" />
            </Link>
          </div>
          <div className="card">
            <Link to={"/PJ"}>
              <img src={demais_faturas} className="image_card" />
            </Link>
          </div>
        </div>
      </div>
      <div className="estrutura_rodape">
        <Rodape />
      </div>
    </div>
  );
};

export default Faturas;
