import React from "react";
import "../../styles.css";

export const Rodape = () => {
  return (
    <div>
      <div className="rodape">
        <div className="text">
          Unimed Sudoeste - Avenida Otávio Santos, 147 - Recreio - 45020-750 -
          Vitória da Conquista/BA - Fone: (77) 2101-9393
        </div>
      </div>
    </div>
  );
};
export default Rodape;
