import React from "react";
import { Link } from "react-router-dom";
import Cabecalho from "../../componentes/cabecalho";
import Rodape from "../../componentes/rodape";
import coletivo_empresarial from "../../images/cartao_COLETIVO EMPRESARIAL-01.png";
import coletivo_adesao from "../../images/cartao_COLETIVO POR ADESAO.png";
import individual_familiar from "../../images/cartao_INDIVIDUAL-FAMILIAR-01.png";
import "../../styles.css";

export const Tipo_Plano = ({}) => {
  return (
    <div>
      <div className="estrutura_cabecalho">
        <Cabecalho />
      </div>
      <div className="estrutura_conteudo">
        <div className="titulo_tipo_plano">
          CLIQUE NA CARTEIRINHA QUE REPRESENTE O SEU TIPO DE PLANO PARA
          CONTINUAR.
        </div>
        <div className="cards_container">
          <Link to={"/PORTAL_NOVO"} style={{ textDecoration: "none" }}>
            <div className="carteira">
              <img
                src={coletivo_empresarial}
                alt="COLETIVO EMPRESARIAL"
                width={"100%"}
              />
              <div className="tipo_plano">COLETIVO EMPRESARIAL</div>
            </div>
          </Link>
          <Link to={"/PASSO_A_PASSO"} style={{ textDecoration: "none" }}>
            <div className="carteira">
              <img
                src={coletivo_empresarial}
                alt="DEMITIDO APOSENTADO"
                width={"100%"}
              />
              <div className="tipo_plano">
                COLETIVO EMPRESARIAL/DEMITIDO APOSENTADO
              </div>
            </div>
          </Link>
          <Link to={"/PORTAL_NOVO"} style={{ textDecoration: "none" }}>
            <div className="carteira">
              <img
                src={coletivo_adesao}
                alt="COLETIVO POR ADESÃO"
                width={"100%"}
              />
              <div className="tipo_plano">COLETIVO POR ADESÃO</div>
            </div>
          </Link>
          <Link to={"/PASSO_A_PASSO"} style={{ textDecoration: "none" }}>
            <div className="carteira">
              <img
                src={individual_familiar}
                alt="INDIVIDUAL/FAMILIAR"
                width={"100%"}
              />
              <div className="tipo_plano">INDIVIDUAL/FAMILIAR</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="estrutura_rodape">
        <Rodape />
      </div>
    </div>
  );
};

export default Tipo_Plano;
