import React from "react";
import primeiro_acesso from "../../images/PRIMEIRO ACESSO.png";
import texto2 from "../../images/Texto 02 Padrão.png";
import texto3 from "../../images/Texto 03 Padrão.png";
import texto5 from "../../images/ESTIPULANTE-CONTRATANTE.png";
import download from "../../images/banner.png";
import senha from "../../images/Senha.png";
import usuario from "../../images/Usuario.png";
import "../../styles.css";
import Cabecalho from "../../componentes/cabecalho";
import Rodape from "../../componentes/rodape";
import tutorial from "../../images/tutorial.pdf";

export const PJ_Portal_Novo = ({}) => {
  const redirecionar_portal = () => {
    window.open("https://portal.unimedsudoeste.com.br/", "_blank");
  };

  return (
    <div>
      <div className="estrutura_cabecalho">
        <Cabecalho />
      </div>
      <div className="estrutura_conteudo">
        <div className="card_text">
          <img src={primeiro_acesso} className="image_card" />
        </div>
        <div className="card_text">
          <img src={texto2} className="image_card" />
        </div>
        <div className="card_text2">
          <img src={texto5} className="image_card" width={475} />
        </div>
        <div className="div_row">
          <div className="coletivo_empresarial">
            <img src={usuario} className="image_card" />
          </div>
          <div className="coletivo_empresarial">
            <img src={senha} className="image_card" />
          </div>
        </div>
        <div className="card_text">
          <img src={texto3} className="image_card" />
        </div>
        <a href={tutorial} download="tutorial.pdf">
          <button
            onClick={() => redirecionar_portal()}
            style={{ border: "none", backgroundColor: "white" }}
          >
            <div className="card_text_click">
              <img src={download} className="image_card" width={475} />
            </div>
          </button>
        </a>
      </div>
      <div className="estrutura_rodape">
        <Rodape />
      </div>
    </div>
  );
};

export default PJ_Portal_Novo;
