import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Tipo_Plano from "./pages/Tipo_Plano";
import PF from "./pages/PF";
import PJ from "./pages/PJ";
import PJ_Portal_Novo from "./pages/PJ_Portal_Novo";
import Passo_Passo from "./pages/Passo_Passo";
import Demitido_Aposentado from "./pages/Demitido_Aposentado";
import Faturas from "./pages/Faturas";

const Rota = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Tipo_Plano />} />
      <Route exact path="/PF" element={<PF />} />
      <Route exact path="/PJ" element={<PJ />} />
      <Route exact path="/PORTAL_NOVO" element={<PJ_Portal_Novo />} />
      <Route exact path="/PASSO_A_PASSO" element={<Passo_Passo />} />
      <Route exact path="/VENCIMENTO" element={<Demitido_Aposentado />} />
      <Route exact path="/Faturas" element={<Faturas />} />
    </Routes>
  </BrowserRouter>
);

export default Rota;
