import React from "react";
import Cabecalho from "../../componentes/cabecalho";
import Rodape from "../../componentes/rodape";
import Unimed_Passo_Passo from "../../images/Unimed_Passo_Passo.png";
import "../../styles.css";

export const Passo_Passo = ({}) => {
  const redirecionar_portal = () => {
    window.open("https://portal.unimedsudoeste.com.br/", "_blank");
  };

  return (
    <div>
      <div className="estrutura_cabecalho">
        <Cabecalho />
      </div>
      <div className="estrutura_conteudo">
        <div className="cards_container">
          <div className="card_text">
            <img
              src={Unimed_Passo_Passo}
              className="image_card_base"
              width={875}
            />
          </div>
        </div>
        <button
          onClick={() => redirecionar_portal()}
          style={{ border: "none", backgroundColor: "white" }}
        >
          <div className="card_text_button">
            CLIQUE AQUI PARA ACESSAR
          </div>
        </button>
      </div>
      <div className="estrutura_rodape">
        <Rodape />
      </div>
    </div>
  );
};

export default Passo_Passo;
