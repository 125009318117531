import React from "react";
import logo_unimed from "../../images/logo_unimed.png";
import "../../styles.css";
import { Link } from "react-router-dom";

export const Cabecalho = () => {
  return (
    <div>
      <div className="cabecalho">
        <Link
          className="logo_unimed"
          to={"/"}
          style={{ textDecoration: "none" }}
        >
          <img src={logo_unimed} className="image_logo" />
        </Link>
      </div>
      <div className="cabecalho_faixa" />
    </div>
  );
};
export default Cabecalho;
