import React from "react";
import Rota from "./routes";

function App() {
    return (
      <div>
         <Rota />
      </div>
    );
  }

export default App;
