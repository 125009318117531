import React from "react";
import { Link } from "react-router-dom";
import PJ_01_01 from "../../images/Balao PJ_01-01.png";
import PJ_02_01 from "../../images/Balao PJ_02-01.png";
import "../../styles.css";
import Cabecalho from "../../componentes/cabecalho";
import Rodape from "../../componentes/rodape";

export const PF = ({}) => {
  return (
    <div>
      <div className="estrutura_cabecalho">
        <Cabecalho />
      </div>
      <div className="estrutura_conteudo">
        <div className="container">
          <div className="card">
            <Link to={"/VENCIMENTO"}>
              <img
                src={PJ_01_01}
                alt="SEU PLANO FOI CONTRATADO ATÉ DE DEZEMBRO DE 2021."
                className="image_card"
              />
            </Link>
          </div>
          <div className="card">
            <Link to={"/PASSO_A_PASSO"}>
              <img
                src={PJ_02_01}
                alt="SEU PLANO FOI CONTRATADO A PARTIR DE JANEIRO DE 2022."
                className="image_card"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="estrutura_rodape">
        <Rodape />
      </div>
    </div>
  );
};

export default PF;
